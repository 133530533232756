/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ pageTitle, pageMeta, pageLang, featuredImagePath }) {
    const data = useStaticQuery(
    graphql`
    {
      allFile( filter: { internal: { mediaType: { regex: "images/" } } } ) {
        edges {
          node {
            relativePath
            publicURL
          }
        }
      }
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
    `
    )

    var siteUrl = data.site.siteMetadata.siteUrl
    
    function searchFeaturedImage(imageName, myArray){
        for (var i=0; i < myArray.length; i++) {
            if (myArray[i].node.relativePath === imageName) {
                return myArray[i].node.publicURL
            }
        }
    }
    
    var featuredImageSrc = searchFeaturedImage(featuredImagePath, data.allFile.edges)
    
    function search(nameKey, myArray){
        for (var i=0; i < myArray.length; i++) {
            if (myArray[i].name === nameKey) {
                return myArray[i]
            }
        }
    }

    var description = search("description", pageMeta)
       description = description.content
    console.log()
  return (
      <>
    <Helmet
      defer={false}
      htmlAttributes={{
        pageLang,
      }}
      title={pageTitle}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: pageTitle,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
            property: 'og:image',
            content: siteUrl + featuredImageSrc
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:title`,
          content: pageTitle,
        },
        {
          name: `twitter:description`,
          content: description,
        },
      ]}
    />
      </>
  )
}

SEO.defaultProps = {
  pageLang: `en_US`,
  pageMeta: [],
  pageTitle: `test`,
}

export default SEO